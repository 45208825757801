import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { GraphiQLPage } from '@backstage-community/plugin-graphiql';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {
  RouterProps,
  ScaffolderPage,
  scaffolderPlugin,
} from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ReportIssue,
  TextSize,
  LightBox,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import {
  SettingsLayout,
  UserSettingsPage,
} from '@backstage/plugin-user-settings';
import { AdvancedSettings } from './components/advancedSettings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { CustomizableHomePage } from './components/home/CustomizableHomePage';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import {
  configApiRef,
  githubAuthApiRef,
  gitlabAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';
import { LighthousePage } from '@backstage-community/plugin-lighthouse';
import { FossaPage } from '@backstage-community/plugin-fossa';
import { AnnouncementsPage } from '@backstage-community/plugin-announcements';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToolboxPage } from '@drodil/backstage-plugin-toolbox';
import {
  ScaffolderFieldExtensions,
  ScaffolderLayouts,
} from '@backstage/plugin-scaffolder-react';
import { DynamicPickFieldExtension } from '@premise/plugin-dynamic-pick-extension';
import { ValidateKebabCaseFieldExtension } from './scaffolder/ValidateKebabCase';
import { TwoColumnLayout } from './components/scaffolder/customScaffolderLayouts';
import { timeSaverPermission } from '@tduniec/backstage-plugin-time-saver-common';
import { TimeSaverPage } from '@tduniec/backstage-plugin-time-saver';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { RagModal } from '@alithya-oss/plugin-rag-ai';
import { InfraWalletPage } from '@electrolux-oss/plugin-infrawallet';
import { TechInsightsScorecardPage } from '@backstage-community/plugin-tech-insights';
// import { CostInsightsPage } from '@backstage-community/plugin-cost-insights';
import {
  NotificationsPage,
  UserNotificationSettingsCard,
} from '@backstage/plugin-notifications';
import { RbacPage } from '@backstage-community/plugin-rbac';

import { UnifiedThemeProvider, themes } from '@backstage/theme';
import FlareIcon from '@material-ui/icons/Flare';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import { apertureTheme } from './themes/aperture';
import { alithyaTheme } from './themes/alithya';
import { benevaTheme } from './themes/beneva';
import { draculaTheme } from './themes/dracula';

import * as plugins from './plugins';

const renderSignInPage = (props: any) => {
  let providers: Array<Object> = [
    {
      id: 'github',
      title: 'Github',
      message: 'Sign in using Github',
      apiRef: githubAuthApiRef,
    },
    {
      id: 'gitlab',
      title: 'Gitlab',
      message: 'Sign in using Gitlab',
      apiRef: gitlabAuthApiRef,
      enableExperimentalRedirectFlow: true,
    },
  ];

  if (
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useApi(configApiRef).getOptionalString('auth.environment') === 'development'
  )
    providers = ['guest', ...providers];

  return <SignInPage {...props} auto providers={providers} />;
};

const scaffolderPageOptions: RouterProps = {
  groups: [
    {
      title: 'Recommended',
      filter: entity =>
        entity?.metadata?.tags?.includes('recommended') ?? false,
    },
    {
      title: 'Experimental',
      filter: entity =>
        entity?.metadata?.tags?.includes('experimental') ?? false,
    },
    {
      title: 'Deprecated',
      filter: entity => entity?.metadata?.tags?.includes('deprecated') ?? false,
    },
    {
      title: 'Other',
      filter: entity =>
        !(
          entity?.metadata?.tags?.includes('recommended') ||
          entity?.metadata?.tags?.includes('experimental') ||
          entity?.metadata?.tags?.includes('deprecated')
        ) || false,
    },
  ],
  // headerOptions: {
  //   title: 'Gabarits',
  //   subtitle: '',
  //   pageTitleOverride: 'Gabarits',
  // },
};

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  components: {
    SignInPage: props => renderSignInPage(props),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'alithya',
      title: 'Alithya',
      variant: 'light',
      icon: <FlareIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={alithyaTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'dracula',
      title: 'Dracula',
      variant: 'dark',
      icon: <FlareIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={draculaTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'beneva-light',
      title: 'Beneva',
      variant: 'light',
      icon: <FlareIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={benevaTheme} children={children} />
      ),
    },
    {
      id: 'aperture',
      title: 'Aperture',
      variant: 'light',
      icon: <FlareIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={apertureTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'light',
      title: 'Light',
      variant: 'light',
      icon: <FlareIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={themes.light} children={children} />
      ),
    },
    {
      id: 'dark',
      title: 'Dark',
      variant: 'dark',
      icon: <Brightness2Icon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={themes.dark} children={children} />
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="home" />} />
    <FeatureFlagged with="customizable-home-page-preview">
      <Route path="/home" element={<HomepageCompositionRoot />}>
        <CustomizableHomePage />
      </Route>
    </FeatureFlagged>
    <FeatureFlagged without="customizable-home-page-preview">
      <Route path="/home" element={<HomepageCompositionRoot />}>
        <HomePage />
      </Route>
    </FeatureFlagged>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <Mermaid
          config={{
            theme: 'material',
            themeVariables: { lineColor: '#000000' },
          }}
        />
        <LightBox />
        <TextSize />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={<ScaffolderPage {...scaffolderPageOptions} />}
    >
      <ScaffolderFieldExtensions>
        <DynamicPickFieldExtension />
        <ValidateKebabCaseFieldExtension />
      </ScaffolderFieldExtensions>
      <ScaffolderLayouts>
        <TwoColumnLayout />
      </ScaffolderLayouts>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />}>
      <SettingsLayout.Route path="/advanced" title="Advanced">
        <AdvancedSettings />
      </SettingsLayout.Route>
      <SettingsLayout.Route path="/notifications" title="Notifications">
        <UserNotificationSettingsCard
          originNames={{ 'plugin:scaffolder': 'Scaffolder' }}
        />
      </SettingsLayout.Route>
    </Route>
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/devtools" element={<DevToolsPage />}>
      {customDevToolsPage}
    </Route>
    <Route path="/fossa" element={<FossaPage />} />
    <Route path="/lighthouse" element={<LighthousePage />} />
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/toolbox" element={<ToolboxPage />} />
    <Route
      path="/time-saver"
      element={
        <RequirePermission permission={timeSaverPermission}>
          <TimeSaverPage />
        </RequirePermission>
      }
    />
    <Route path="/graphiql" element={<GraphiQLPage />} />
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/infrawallet" element={<InfraWalletPage />} />
    <Route path="/tech-insights" element={<TechInsightsScorecardPage />} />
    {/* <Route path="/cost-insights" element={<CostInsightsPage />} /> */}
    <Route path="/notifications" element={<NotificationsPage />} />
    <Route path="/rbac" element={<RbacPage />} />;
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <RagModal />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
